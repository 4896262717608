// const url = "http://103.41.205.45:1952";
// const master = "http://103.41.205.45:1936";
// const membership = "http://103.41.205.45:36";
// const isMakassarKey = 'KvrK5UcE3rR4jhU90VQ3Q268LCXWB4J8';
// const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
const url = "https://chisholmapi.niecindonesia.com";
const master = "https://api2.niecindonesia.com";

const API_URL = {
    url,
    master
}

export default API_URL;

// export default api_url;