/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';
//import api url
import API_URL from  './../../../ApiUrl';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import SweetAlert from 'sweetalert2-react';
// core components
//import ExamplesNavbar from "./../components/molecules/Navbars/ExamplesNavbar.js";
//import LandingPageHeader from "./../components/molecules/Headers/LandingPageHeader.js";
//import DemoFooter from "./../components/molecules/Footers";

import { ProfilePageHeader, ExamplesNavbar, DemoFooter, Register } from './../../../components/molecules/';
import { grey } from "ansi-colors";
import classnames from "classnames";
import Carousel from 'react-multi-carousel';
import { FaRegCalendarAlt } from 'react-icons/fa';



class LandingPage extends Component {
  state = {
    nama_lengkap:"",
    no_telp:"",
    email:"",
    id_propinsi:"",
    id_kabkota:"",
    message:"Error !",
    error: {},
    showSwal: false,
    formAlert:{display:'none'},
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    dataPropinsi: [],
    dataKabkota: [],
    dataKabkotafilter:[],
    aktifKonten:[],
    checkemail:"",
    pesanEmailError:"",
  } 
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    //nama
    if(!this.state.nama_lengkap){
        formIsValid = false;
        errors.nama_lengkap = "Nama wajib diisi";
        this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
        formIsValid = false;
        errors.no_telp = "No telepon wajib diisi";
        this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
        formIsValid = false;
        errors.email = "Email wajib diisi";
        this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
        let lastAtPos  = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
            formIsValid = false;
            errors.email = "Email tidak valid";  
            this.inputEmail.style = this.state.inputBorder;  
        }
    }
    if(this.state.checkemail === "Tolak"){
      formIsValid = false;
      errors.email = this.state.pesanEmailError;
      this.inputEmail.style = this.state.inputBorder;
  }
     //pripinsi
     if(!this.state.id_propinsi){
      formIsValid = false;
      errors.id_propinsi = "Provinsi wajib diisi";
      this.inputPropinsi.style = this.state.inputBorder;
    }
    //kabkota
    if(!this.state.id_kabkota){
      formIsValid = false;
      errors.id_kabkota = "Kabupaten / Kota wajib diisi";
      this.inputKabKota.style = this.state.inputBorder;
    }
    this.setState({error:errors});
    return formIsValid;
  }

  onChangeEmail = (e) => {
    this.setState({email:e.target.value})
    this.checkEmail();   
  }

  registrationSubmit(e){
    e.preventDefault();
    // if(this.handleValidation()){
    //   this.sendData();
    // }
      if(this.handleValidation()){
          this.sendData();
      }else{
          this.setState({
              formAlert:{display:'unset'}, 
              formAlertClassName:'alert alert-warning',
              message:"Error !"
          })
      }
  }

  sendData(){
    this.setState({
      message:"Proses simpan, mohon menunggu beberapa saat.",
      formAlert:{display:'block'},
      formAlertClassName:'alert alert-warning',
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('nama_lengkap',this.state.nama_lengkap);
    formData.append('no_wa',this.state.no_telp);
    formData.append('email',this.state.email);
    formData.append('id_propinsi',this.state.id_propinsi);
    formData.append('id_kabkota',this.state.id_kabkota);
  
    axios({
      method: 'post',
      url:API_URL.url+'/peserta/register',
      data:formData,
      headers: {
        'Accept': 'application/json'
      }
    }).then((reply)=>{
        //console.log(reply)
        if(reply.data[0].id_register !== undefined){
          this.setState({showSwal:true})
        }else{
          // alert(reply.data);
          this.setState({btnDisabled:false})
          // this.hideAlert();
          alert("Pendaftaran Berhasil!")
          window.location.reload();
        }
    }).catch(function(reply){
        console.log(reply)
    });
  }

  onBlurNamaLengkap(e){
    e.preventDefault()
    if(this.state.nama_lengkap.length > 2){
        this.inputNamaLengkap.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.nama_lengkap = '';                                 
            return { error };                   
        })
    }
  }
  onBlurNoTelp(e){
      e.preventDefault()
      if(this.state.no_telp.length > 0){
          this.inputNoTelp.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.no_telp = '';                                 
              return { error };                   
          })
      }else{
        this.inputNoTelp.style = this.state.inputBorder;
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.error);  
            errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
            this.setState({error:errors});                  
        })
      }
  }
  onBlurEmail(e){
    e.preventDefault()
    this.checkEmail();
    if(this.state.email.length > 5){
        this.inputEmail.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.email = '';                                 
            return { error };                   
        })
    }
  }
  onBlurPropinsi(e){
    e.preventDefault()
    if(this.state.id_propinsi.length > 2){
        this.inputPropinsi.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_propinsi = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKabkota(e){
    e.preventDefault()
    if(this.state.id_kabkota.length > 2){
        this.inputKabKota.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_kabkota = '';                                 
            return { error };                   
        })
    }
  }

  getDataPropinsi(){
    const url = API_URL.master+'/propinsi';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataPropinsi: data })
        console.log(this.state.dataPropinsi)
    })
  }

  getDataKabkota(){
    const url = API_URL.master+'/kabkota';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKabkota: data })
        console.log(this.state.dataKabkota)
    })
  }

  setPropinsi(e){
    e.preventDefault();
      this.setState({
          id_propinsi:e.target.value
      })

      const data = Object.values(this.state.dataKabkota);
      const id = e.target.value;
      const filterData = data.filter(dataFilter => {
        return dataFilter.id_propinsi == id;
        });
      this.setState({dataKabkotafilter:filterData})
      console.log(filterData)
  }

  setKabkota(e){
    e.preventDefault();
      this.setState({
          id_kabkota:e.target.value
      })
      console.log(this.state.id_kabkota)
  }

  radioChangeHandler = (event) =>{
    this.setState({
        konsultan:event.target.value
    })
  }
  componentDidMount() {
    this.getDataPropinsi();
    this.getDataKabkota();
  }

  checkEmail(){
    const url = API_URL.url+'/peserta/cekmail';

    var formData = new FormData();
    formData.append('email',this.state.email);
    axios({
        method: 'post',
        url: url,
        data:formData,
        headers: {
          'Accept': 'application/json'
        }
        // config:{headers:{'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${API_URL.isMakassarKey}`}}
    }).then(response => response.data)
    .then((data) => {
        this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
        // console.log('cek data email', this.state.checkemail)
        // console.log('cek pesan email', this.state.pesanEmailError)
        console.log('cek email1', this.state.pesanEmailError)
    })
  }

  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputNamaSekolahlah.value = "";
    this.inputKelasSemester.value = "";
    this.inputPassword.value = "";
    this.selectPropinsi.value = "";
    this.selectKabkota.value = "";
    this.inputRePassword.value = "";
    this.inputKodePromo.value = "";
  }

  render() {
 
  return (
    <>
      <ProfilePageHeader />

    <div className="main">
      <div className="section" style={{ backgroundColor: ''}} align="center">
        <Container>
            <Row>
              <Col lg="6" md="12">
                  <h3 className="desc-left">
                      <FaRegCalendarAlt />  Rabu, 8 Desember 2021 <br /> &nbsp;&nbsp;&nbsp;&nbsp; 14:00 WITA
                  </h3>
              </Col>
              <Col lg="6" md="12">
                  <h3 className="desc-right">
                     Hospitality Program
                  </h3>
              </Col>
            </Row>
            <hr/>
            <Row>  
              <Col lg="6" md="12">    
                 <h3 className="desc-left">
                      <FaRegCalendarAlt /> Kamis, 9 Desember 2021 <br /> &nbsp;&nbsp;&nbsp;&nbsp; 14:00 WITA
                  </h3>
              </Col>
              <Col lg="6" md="12">    
                 <h3 className="desc-right">
                     Health Program
                  </h3>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col lg="6" md="12">     
                  <h3 className="desc-left">
                  <FaRegCalendarAlt /> Jumat, 10 Desember 2021 <br /> &nbsp;&nbsp;&nbsp;&nbsp; 14:00 WITA
                  </h3>
              </Col>
              <Col lg="6" md="12">     
                  <h3 className="desc-right">
                  Engineering Program
                  </h3>
              </Col>
            </Row>
          </Container>
        </div>
      <div className="section page-header" style={{ 
        backgroundImage: "url(" + require("../../../assets/img/section-03.png") + ")"}}>
        <Container>
          <Row>
            <Col lg="6" md="6">
              <div className="icons-container mt-5" style={{ fontFamily:'' }}>
                <div>
                  <h3 className="headtitle-left mb-5">
                  </h3>
                  <p className="desc-left">
                  Pathway to Victoria Scholarship Program <br />
                  AUD 2,500
                  <hr/>
                  International Student Academic <br />
                  Scholarship up to AUD 1,500
                  <hr/>
                  International Remote Higher Education <br />
                  Scholarship AUD 1,500
                  <hr/>
                  International Student Second Semester <br />
                  Scholarship AUD 1,500
                  </p>
                </div>
              </div>
             </Col>
            <Col lg="6" md="6" style={{ }}>
              
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section" style={{ backgroundColor: '#2e3192', color:"white"}} align="center">
        <Container>
        <Row>
            <Col lg="12" md="12" >
                <div className="icons-container" style={{ fontFamily:'' }}>
                  <div>
                    <h3 className="headtitle-w mb-5">Why Chisholm? </h3>
                  </div>
                </div>
            </Col>
            <Col lg="12" md="12">
            <Row>
                <Col md="3" sm="12" className="mt-5 mb-5">
                    <img style={{ maxWidth:'65%', maxHeight:'75%' }}
                    alt="..."
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-01.png")}
                    />
                        <p className="desc">
                        Kampus public (milik pemerintah Australia)
                        </p>
                </Col>
                <Col md="3" sm="12" className="mt-5 mb-5">
                    <img style={{ maxWidth:'65%', maxHeight:'75%' }}
                    alt="..."
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-02.png")}
                    />
                        <p className="desc">
                        International Education Provider of 
                        The Year – Study Melbourne 2020
                        </p>
                </Col>
                <Col md="3" sm="12" className="mt-5 mb-5">
                    <img style={{ maxWidth:'65%', maxHeight:'75%' }}
                    alt="..."
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-04.png")}
                    />
                        <p className="desc">
                        Lulusan kualitas tinggi dan siap
                        untuk bekerja sesuai dengan 
                        kebutuhan industry
                        </p>
                </Col>
                <Col md="3" sm="12" className="mt-5 mb-5">
                    <img style={{ maxWidth:'65%', maxHeight:'75%' }}
                    alt="..."
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-03.png")}
                    />
                        <p className="desc">
                        Tersedia banyak beasiswa
                        </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section" style={{ backgroundColor: '#E6E6E6', color:"black"}} align="center">
        <Container>
        <Col lg="12" md="12" >
            <div className="icons-container" style={{ fontFamily:'' }}>
              <div>
                <h3 className="headtitle-b mb-5">Jurusan Mahasiswa Internasional </h3>
              </div>
            </div>
        </Col>
        <Row>
            <Col md="4" sm="12" className="mt-5 mb-5">
                <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                alt="..."
                src={require("../../../assets/img/jurusan/img-01.png")}
                className="responsiveImg"
                />
                    <p className="desc-blue">
                    Accounting 
                    </p>
            </Col>
            <Col md="4" sm="12" className="mt-5 mb-5">
            <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                alt="..."
                src={require("../../../assets/img/jurusan/img-02.png")}
                className="responsiveImg"
                />
                    <p className="desc-blue">
                    Alcohol other Drugs <br />
                    and Mental Health
                    </p>
            </Col>
            <Col md="4" sm="12" className="mt-5 mb-5">
            <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                alt="..."
                src={require("../../../assets/img/jurusan/img-03.png")}
                className="responsiveImg"
                />
                    <p className="desc-blue">
                    Community Services
                    </p>
            </Col>
          </Row>
          <Row>
            <Col md="4" sm="12" className="mt-5 mb-5">
                <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                alt="..."
                src={require("../../../assets/img/jurusan/img-04.png")}
                className="responsiveImg"
                />
                    <p className="desc-blue">
                    Hospitality : Commercial Cookery <br />
                    and Pastry
                    </p>
            </Col>
            <Col md="4" sm="12" className="mt-5 mb-5">
            <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                alt="..."
                src={require("../../../assets/img/jurusan/img-05.png")}
                className="responsiveImg"
                />
                    <p className="desc-blue">
                    Early Childhood Education
                    </p>
            </Col>
            <Col md="4" sm="12" className="mt-5 mb-5">
            <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                alt="..."
                src={require("../../../assets/img/jurusan/img-06.png")}
                className="responsiveImg"
                />
                    <p className="desc-blue">
                    Engineering
                    </p>
            </Col>
          </Row>
        </Container>
        <div className="section2" style={{ fontFamily:'' }}>
        <Container>

            </Container>
            </div>
      </div>

      <div className="section" style={{ backgroundColor: '#2e3192'}} align="center">
        <Container>
            <Row>
              <Col lg="12" md="12">
                <Register />
              </Col>
            </Row>
          </Container>
        </div>



</div>      

      <DemoFooter />
    </>
  );
            }
}

export default LandingPage;
