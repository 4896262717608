import React, { Component } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';
//import api url
import API_URL from  './../../../ApiUrl';
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from "reactstrap";
// import SweetAlert from 'sweetalert2-react';
import SweetAlert from 'react-bootstrap-sweetalert';


import { grey } from "ansi-colors";
import classnames from "classnames";

class Register extends Component {
  state = {
    nama_lengkap:"",
    no_telp:"",
    email:"",
    id_propinsi:"",
    id_kabkota:"",
    id_rencana_biaya:"",
    id_rencana_jadwal:"",
    code_jenjang:"",
    code_kelas:"",
    sekolah_kampus:"",
    ket_lainnya:"",
    message:"Error !",
    error: {},
    showSwal: false,
    formAlert:{display:'none'},
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    dataPropinsi: [],
    dataKabkota: [],
    dataKabkotafilter:[],
    dataJenPen: [],
    dataKelasSemester: [],
    dataKelasSemesterFilter:[],
    aktifKonten:[],
    checkemail:"",
    pesanEmailError:"",
  } 
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    //nama
    if(!this.state.nama_lengkap){
        formIsValid = false;
        errors.nama_lengkap = "Nama wajib diisi";
        this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
        formIsValid = false;
        errors.no_telp = "No telepon wajib diisi";
        this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
        formIsValid = false;
        errors.email = "Email wajib diisi";
        this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
        let lastAtPos  = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
            formIsValid = false;
            errors.email = "Email tidak valid";  
            this.inputEmail.style = this.state.inputBorder;  
        }
    }
    if(this.state.checkemail === "Tolak"){
      formIsValid = false;
      errors.email = this.state.pesanEmailError;
      this.inputEmail.style = this.state.inputBorder;
    }
     //Rencana Biaya Kuliah
     if(!this.state.id_rencana_biaya){
      formIsValid = false;
      errors.id_rencana_biaya = "Rencana Biaya Kuliah wajib diisi";
      this.inputRencanaBiaya.style = this.state.inputBorder;
    }
      //Rencana Jadwal Kuliah
      if(!this.state.id_rencana_jadwal){
      formIsValid = false;
      errors.id_rencana_jadwal = "Rencana Jadwal Kuliah wajib diisi";
      this.inputRencanaJadwal.style = this.state.inputBorder;
    }
     //pripinsi
     if(!this.state.id_propinsi){
      formIsValid = false;
      errors.id_propinsi = "Provinsi wajib diisi";
      this.inputPropinsi.style = this.state.inputBorder;
    }
    //kabkota
    if(!this.state.id_kabkota){
      formIsValid = false;
      errors.id_kabkota = "Kabupaten / Kota wajib diisi";
      this.inputKabKota.style = this.state.inputBorder;
    }
  

    this.setState({error:errors});
    return formIsValid;
  }

  onChangeEmail = (e) => {
    this.setState({email:e.target.value})
    this.checkEmail();   
  }

  registrationSubmit(e){
    e.preventDefault();
    // if(this.handleValidation()){
    //   this.sendData();
    // }
      if(this.handleValidation()){
          this.sendData();
      }else{
          this.setState({
              formAlert:{display:'unset'}, 
              formAlertClassName:'alert alert-warning',
              message:"Error !"
          })
      }
  }

  sendData(){
    this.setState({
      message:"Proses simpan, mohon menunggu beberapa saat.",
      formAlert:{display:'block'},
      formAlertClassName:'alert alert-warning',
      btnDisabled: true,
      showSwal : true
    });

    var formData = new FormData();
    formData.append('nama_lengkap',this.state.nama_lengkap);
    formData.append('no_wa',this.state.no_telp);
    formData.append('email',this.state.email);
    formData.append('id_propinsi',this.state.id_propinsi);
    formData.append('id_kabkota',this.state.id_kabkota);
    formData.append('id_rencana_biaya',this.state.id_rencana_biaya);
    formData.append('id_rencana_jadwal',this.state.id_rencana_jadwal);
 
    axios({
      method: 'post',
      url:API_URL.url+'/peserta/register',
      data:formData,
      headers: {
        'Accept': 'application/json'
      }
    }).then((reply)=>{
        if(reply.data[0].id_register !== undefined){
          this.setState({showSwal:true})
        }else{
          this.setState({btnDisabled:false})
        }
    }).catch(function(reply){
        console.log(reply)
    });
  }

  onBlurNamaLengkap(e){
    e.preventDefault()
    if(this.state.nama_lengkap.length > 2){
        this.inputNamaLengkap.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.nama_lengkap = '';                                 
            return { error };                   
        })
    }
  }
  onBlurNoTelp(e){
      e.preventDefault()
      if(this.state.no_telp.length > 0){
          this.inputNoTelp.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.no_telp = '';                                 
              return { error };                   
          })
      }else{
        this.inputNoTelp.style = this.state.inputBorder;
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.error);  
            errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
            this.setState({error:errors});                  
        })
      }
  }
  onBlurEmail(e){
    e.preventDefault()
    this.checkEmail();
    if(this.state.email.length > 5){
        this.inputEmail.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.email = '';                                 
            return { error };                   
        })
    }
  }
  onBlurRencanaBiaya(e){
    e.preventDefault()
    if(this.state.id_rencana_biaya.length > 2){
        this.inputRencanaBiaya.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_rencana_biaya = '';                                 
            return { error };                   
        })
    }
  }
  onBlurRencanaJadwal(e){
    e.preventDefault()
    if(this.state.id_rencana_jadwal.length > 2){
        this.inputRencanaJadwal.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_rencana_jadwal = '';                                 
            return { error };                   
        })
    }
  }
 
  onBlurPropinsi(e){
    e.preventDefault()
    if(this.state.id_propinsi.length > 2){
        this.inputPropinsi.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_propinsi = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKabkota(e){
    e.preventDefault()
    if(this.state.id_kabkota.length > 2){
        this.inputKabKota.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_kabkota = '';                                 
            return { error };                   
        })
    }
  }

  
  getDataPropinsi(){
    const url = API_URL.master+'/propinsi';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataPropinsi: data })
        console.log(this.state.dataPropinsi)
    })
  }

  getDataKabkota(){
    const url = API_URL.master+'/kabkota';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKabkota: data })
        console.log(this.state.dataKabkota)
    })
  }

  setPropinsi(e){
    e.preventDefault();
      this.setState({
          id_propinsi:e.target.value
      })

      const data = Object.values(this.state.dataKabkota);
      const id = e.target.value;
      const filterData = data.filter(dataFilter => {
        return dataFilter.id_propinsi == id;
        });
      this.setState({dataKabkotafilter:filterData})
      console.log(filterData)
  }

  setKabkota(e){
    e.preventDefault();
      this.setState({
          id_kabkota:e.target.value
      })
      console.log(this.state.id_kabkota)
  }

 
  componentDidMount() {
    this.getDataPropinsi();
    this.getDataKabkota();
  }

  checkEmail(){
    const url = API_URL.url+'/peserta/cekmail';

    var formData = new FormData();
    formData.append('email',this.state.email);
    axios({
        method: 'post',
        url: url,
        data:formData,
        headers: {
          'Accept': 'application/json'
        }
        // config:{headers:{'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${API_URL.isMakassarKey}`}}
    }).then(response => response.data)
    .then((data) => {
        this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
        // console.log('cek data email', this.state.checkemail)
        // console.log('cek pesan email', this.state.pesanEmailError)
        console.log('cek email1', this.state.pesanEmailError)
    })
  }



  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputPropinsi.value = "";
    this.inputKabkota.value = "";
    this.inputRencanaJadwal = ""; 
    this.inputRencanaBiaya = "";
  }

  render() {

    return (
      <>
        <div className="">
        <div className="" />
          <div className="section" style={{ backgroundColor: '#2e3192'}}>
          <Container>
          <h3 className="headtitle-w mb-5">REGISTER</h3>
              <Row>
                <Col className="ml-auto mr-auto" lg="6" md="6" style={{backgroundColor:"", padding:"0px"}}>
                  <div  style={{ backgroundColor: '', paddingTop:"0px"}}>
                      <form>
                        <Row style={{backgroundColor:"", margin:"0px"}}>
                          <Col className="ml-auto mr-auto" md="" style={{backgroundColor:"", margin:"0px"}}>
                            <Row style={{backgroundColor:""}}>
                              <Col>
                                <FormGroup style={{marginBottom:"0px",  textAlign:'left'}}>
                                  <Label for="nama_lengkap" style={{ color: 'white', fontWeight:'500'}}>Nama Lengkap</Label>
                                  <input md="3" className="form-control" type="nama_lengkap"  id="nama_lengkap" placeholder=""
                                    onChange={(e) => this.setState({nama_lengkap:e.target.value})}
                                    onBlur={(e) => this.onBlurNamaLengkap(e)}
                                    ref={e => this.inputNamaLengkap = e}
                                  />
                                  <label className="error-label-register">
                                      {this.state.error.nama_lengkap}
                                  </label>
                                </FormGroup>
  
                                <FormGroup style={{marginBottom:"0px",  textAlign:'left'}}>
                                  <Label for="inputEmail4" style={{ color: 'white', fontWeight:'500'}}>Email</Label>
                                  <input className="form-control" type="email"  id="inputEmail4" placeholder=""
                                    // onChange={(e) => this.setState({email:e.target.value})}
                                    onChange={this.onChangeEmail} 
                                    onBlur={(e) => this.onBlurEmail(e)}
                                    ref={e => this.inputEmail = e}
                                  />
                                  <label className="error-label-register">
                                      {this.state.error.email}
                                  </label>
                                </FormGroup>
  
                                <FormGroup style={{marginBottom:"0px",  textAlign:'left'}}>
                                  <Label for="no_wa" style={{ color: 'white', fontWeight:'500'}}>Phone / Whatsapp</Label>
                                  <input className="form-control" type="number"  id="no_wa" placeholder=""
                                    onChange={(e) => this.setState({no_telp:e.target.value})}
                                    onBlur={(e) => this.onBlurNoTelp(e)}
                                    ref={e => this.inputNoTelp = e}
                                  />
                                  <label className="error-label-register">
                                      {this.state.error.no_telp}
                                  </label>
                                </FormGroup>
  
                                <FormGroup style={{marginBottom:"0px",  textAlign:'left'}}>
                                  <Label for="id_propinsi" style={{ color: 'white', fontWeight:'500'}}>Provinsi</Label>
                                  <select md="3" className="form-control" type="id_propinsi"  id="id_propinsi" placeholder=""
                                    // onChange={(e) => this.setState({id_propinsi:e.target.value})}
                                    onChange={(e) => this.setPropinsi(e)}
                                    onBlur={(e) => this.onBlurPropinsi(e)}
                                    ref={e => this.inputPropinsi = e}
                                  >
                                    <option value="0">--Pilih Propinsi--</option>
                                    {this.state.dataPropinsi.map((data1)=>(
                                      <option key={data1.id_propinsi} value={data1.id_propinsi}>{data1.nama_propinsi}</option>
                                    ))}
                                  </select>
                                  <label className="error-label-register">
                                      {this.state.error.id_propinsi}
                                  </label>
                                </FormGroup>
  
                                <FormGroup style={{marginBottom:"0px",  textAlign:'left'}}>
                                  <Label for="id_kabkota" style={{ color: 'white', fontWeight:'500'}}>Kab / Kota</Label>
                                  <select md="3" className="form-control" type="id_kabkota"  id="id_kabkota" placeholder=""
                                    // onChange={(e) => this.setState({id_kabkota:e.target.value})}
                                    onChange={(e) => this.setKabkota(e)}
                                    onBlur={(e) => this.onBlurKabkota(e)}
                                    ref={e => this.inputKabKota = e}
                                  >
                                    <option value="0">--Pilih Kab/Kota--</option>
                                    {this.state.dataKabkotafilter.map((data2)=>(
                                      <option key={data2.id_kabkota} value={data2.id_kabkota}>{data2.nama_kabkota}</option>
                                    ))}
                                  </select>
                                  <label className="error-label-register">
                                      {this.state.error.id_kabkota}
                                  </label>
                                </FormGroup>

                                <FormGroup style={{marginBottom:"0px",  textAlign:'left'}}>
                                  <Label for="id_rencana_biaya" style={{ color: 'white', fontWeight:'500'}}>Bagaimana perencanaan biaya kuliah anda</Label>
                                  <select md="3" className="form-control" type="id_rencana_biaya"  id="id_rencana_biaya" placeholder=""
                                    onChange={(e) => this.setState({id_rencana_biaya:e.target.value})}
                                    onBlur={(e) => this.onBlurRencanaBiaya(e)}
                                    ref={e => this.inputRencanaBiaya = e}
                                  >
                                      <option value="0">--Pilih Rencana Biaya Kuliah--</option>
                                      <option value="1">Self-funded (biaya sendiri)</option>
                                      <option value="2">Orang tua / keluarga </option>
                                      <option value="3">Sudah mendapatkan beasiswa pemerintah </option>
                                      <option value="4">Mencari beasiswa partial  </option>
                                      <option value="5">Sementara mendaftar beasiswa  </option>
                                      <option value="6">Saya tidak memiliki sumber dana </option>
                                  </select>
                                  <label className="error-label-register">
                                      {this.state.error.id_rencana_biaya} 
                                  </label>
                                </FormGroup>
                                <FormGroup style={{marginBottom:"0px", textAlign:'left'}}>
                                  <Label for="id_rencana_jadwal" style={{ color: 'white', fontWeight:'500'}}>Rencana jadwal kuliah</Label>
                                  <select md="3" className="form-control" type="id_rencana_jadwal"  id="id_rencana_jadwal" placeholder=""
                                    onChange={(e) => this.setState({id_rencana_jadwal:e.target.value})}
                                    onBlur={(e) => this.onBlurRencanaJadwal(e)}
                                    ref={e => this.inputRencanaJadwal = e}
                                  >
                                      <option value="0">--Pilih Schedule--</option>
                                      <option value="1">8 Desember 2021 - Hospitality Program</option>
                                      <option value="2">9 Desember 2021  - Health Program </option>
                                      <option value="3">10 Desember 2021 - Engineering Program</option>
                                  </select>
                                  <label className="error-label-register">
                                      {this.state.error.id_rencana_jadwal}
                                  </label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row style={{marginTop:"10px", marginBottom:"10px"}}>
                                <Col md="12">
                                  <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                                      {this.state.message}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12" align="center">
                                  <Button disabled={this.state.btnDisabled} className="text-center btn button-utama" type="submit"  onClick={e=>this.registrationSubmit(e)}>Submit</Button>
                                </Col>
                              </Row>
                          </Col>
                          <SweetAlert
                            show={this.state.showSwal}
                            style={{ backgroundColor:'black',
                             color: 'white' }}
                            closeOnCancel= 'true'
                            title="Pendaftaran Berhasil! Cek email kamu sekarang. "
                            showCancel
                            confirmBtnText="Take me there"
                            cancelBtnText="Not Now"
                            onCancel={() => {
                              this.setState({ showSwal: false })
                              window.location.href="/"
                            }}
                            onConfirm={() => 
                            window.location.href="http://prioritas.niecindonesia.com/"}
                          >
                             Bergabunglah dengan Ribuan anak indonesia di NIEC Prioritas Member <br />
                             <h3><del>Rp.599.000,00</del> <br /> Rp. 300.000,00 </h3> <br />gunakan kode promo : <b>niecnewmember</b>
                             <Row>
                             <Col md="4" className="mt-5">
                                <img style={{ maxWidth:'60px', maxHeight:'60px' }}
                                    alt="..."
                                    className="mb-2"
                                    src={require("./../../../assets/img/benefit/Icon-01.png")}
                                />
                                <p className="text-center"><small>
                                   Kontak langsung dengan Ambassador NIEC 
                                </small></p>
                              </Col>
                              <Col md="4" className="mt-5">
                                <img style={{ maxWidth:'60px', maxHeight:'60px' }}
                                  alt="..."
                                  className="mb-2"
                                  src={require("./../../../assets/img/benefit/Icon-02.png")}
                                />
                                <p className="text-center"><small>
                                Kontak langsung dengan Konsultan NIEC
                                </small></p>
                              </Col>
                              <Col md="4" className="mt-5">
                                <img style={{ maxWidth:'60px', maxHeight:'60px' }}
                                  alt="..."
                                  className="mb-2"
                                  src={require("./../../../assets/img/benefit/Icon-03.png")}
                                />
                                <p className="text-center"><small>
                                Gratis Talent Mapping Test
                                </small></p>
                              </Col>
                              </Row>
                              <Row>
                                <Col md="4" className="mt-5">
                                  <img style={{ maxWidth:'60px', maxHeight:'60px' }}
                                    alt="..."
                                    className="mb-2"
                                    src={require("./../../../assets/img/benefit/Icon-04.png")}
                                  />
                                  <p className="text-center"><small>
                                   Akses mudah NIEC Event 
                                  </small></p>
                                </Col>
                                <Col md="4" className="mt-5">
                                  <img style={{ maxWidth:'60px', maxHeight:'60px' }}
                                    alt="..."
                                    className="mb-2"
                                    src={require("./../../../assets/img/benefit/Icon-05.png")}
                                  />
                                  <p className="text-center"><small>
                                  Undangan NIEC Appreciation Night 
                                  </small></p>
                                </Col>
                                <Col md="4" className="mt-5">
                                  <img style={{ maxWidth:'60px', maxHeight:'60px' }}
                                    alt="..."
                                    className="mb-2"
                                    src={require("./../../../assets/img/benefit/Icon-06.png")}
                                  />
                                  <p className="text-center"><small>
                                  Discount 25% Translate Dokumen
                                  </small></p>
                                </Col>
                             </Row>
                            </SweetAlert>
                        </Row> 
                      </form>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
}
}

export default Register;
